const size = {
  xs: '(max-width: 374px)',
  s: '(max-width: 767px)',
  m: '(max-width: 1023px)',
  l: '(max-width: 1199px)',
  xl: '(max-width: 1599px)',
  xxl: '(min-width: 1600px)',
};

// eslint-disable-next-line import/prefer-default-export
export const breakpointsTheme = {
  size,
};
