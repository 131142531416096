import { breakpointsTheme } from './breakpoints';

const colors = {
  grey: '#979797',
  red: '#F50000',
};

const theme = {
  breakpoints: breakpointsTheme,
  colors,
};

export {
  theme,
};
