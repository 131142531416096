import styled from 'styled-components';
import { useTrail, useTransition, animated } from 'react-spring';
import { Link, graphql, useStaticQuery } from 'gatsby';
import React, { useRef } from 'react';

import AnimatedLogo from './AnimatedLogo';
import { theme } from '../constants/theme';
import { RedClose } from '../images/redClose';
import { linkResolver } from '../utils/linkResolver';
import getShortLang from '../utils/getShortLang';
import useBodyScrollLock from '../hooks/useBodyScrollLock';

import PageTitle from './PageTitle';

const config = { mass: 5, tension: 2000, friction: 200 };

const query = graphql`
  query Menu($lang: String){
     prismic {
       allNavigations(lang: $lang) {
        edges {
          node {
            navigation_item {
              link_title
              page {
                _linkType
                ... on PRISMIC_Donaciones {
                  _meta {
                    id
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC_Project_listing_page {
                  _meta {
                    id
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC_News_landing {
                  _meta {
                    id
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC_Homepage {
                  _meta {
                    id
                    uid
                    type
                  }
                }
                ... on PRISMIC_Commissions_page {
                  _meta {
                    id
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC_About {
                  _meta {
                    id
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC_Contact {
                  _meta {
                    id
                    uid
                    type
                    lang
                  }
                }
              }
            }
            _meta {
              lang
            }
          }
        }
      }
    }
  }
`;

const Menu = ({
  lang, onClose, open, location,
}) => {
  const data = useStaticQuery(query);

  const shortLang = getShortLang(lang);

  const transitions = useTransition(open, null, {
    config: {
      duration: 300,
    },
    from: {
      opacity: 0,
      position: 'fixed',
      zIndex: 99,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  });
  const menuItem = data.prismic.allNavigations.edges.find((edge) => {
    if (edge && edge.node) {
      return edge.node._meta.lang === lang;
    }
    return false;
  });

  const menuRef = useRef(null);

  useBodyScrollLock({
    locked: open,
    ref: menuRef,
  });

  const pathname = location && location.pathname ? location.pathname.substring(location.pathname.lastIndexOf('/')) : '/';

  const menuItems = (menuItem && menuItem.node && menuItem.node.navigation_item) || [];

  const trail = useTrail(menuItems.length, {
    config,
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    from: { opacity: 0, x: 20 },
    reverse: !open,
  });

  return transitions.map(({ item, key, props }) => item && (
  <animated.div key={key} style={props}>
    <NavMenu ref={menuRef}>
      <NavMenuContainer>
        <NavMenuHeader>
          <LogoContainer>
            <LogoContainerInner>
              <Link to={shortLang}>
                <AnimatedLogo />
              </Link>
            </LogoContainerInner>
          </LogoContainer>
          <CloseButton onClick={onClose}>
            <RedClose />
          </CloseButton>
        </NavMenuHeader>
        <NavMenuListContainer>
          <NavMenuList>
            {trail.map(({ x, height, ...rest }, index) => (
              <TextAnimation
                key={menuItems[index].link_title}
                // eslint-disable-next-line no-shadow
                style={{ ...rest, transform: x.interpolate((x) => `translate3d(0,${x}px,0)`) }}
              >
                <animated.div style={{ height }}>
                  <NavMenuListItem>
                    <NavMenuListLink to={linkResolver(menuItems[index].page._meta)}>
                      <PageTitle mainTitleText={menuItems[index].link_title} />
                    </NavMenuListLink>
                  </NavMenuListItem>
                </animated.div>
              </TextAnimation>
            ))}
          </NavMenuList>
        </NavMenuListContainer>
        <NavMenuLanguageSelector>
          <LanguageList>
            <LanguageListItem active={lang === 'es-mx'}>
              <Link to={`/es${pathname}`}>Español</Link>
            </LanguageListItem>
            <LanguageListItem active={lang === 'pt-br'}>
              <Link to={`/pt${pathname}`}>Portugues</Link>
            </LanguageListItem>
            <LanguageListItem active={lang === 'en-us'}>
              <Link to={`${pathname}`}>English</Link>
            </LanguageListItem>
          </LanguageList>
        </NavMenuLanguageSelector>
      </NavMenuContainer>
    </NavMenu>
  </animated.div>
  ));
};

const TextAnimation = styled(animated.div)`
  position: relative;
  width: 100%;
  height: auto;
  will-change: transform, opacity;
  overflow: hidden;
`;

const NavMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const NavMenuHeader = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-direction: row;

  padding: 20px 80px;

  @media ${theme.breakpoints.size.l} {
    padding: 22px 40px;
  }
  @media ${theme.breakpoints.size.s} {
    padding: 20px 20px;
  }
`;

const NavMenuLanguageSelector = styled.div`
  height: 60px;
  padding-bottom: 5px;

`;
const LanguageList = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  width: 330px;
  margin: 0 auto;
`;

const LanguageListItem = styled.li`
  padding: 0 23px;
  a {
    color: #FF0606;
    text-decoration: none;
    margin-bottom: 10px;
    line-height: 3;
    border-bottom: ${(props) => (props.active ? '1px solid #FF0606' : 'none')};

    &:hover {
      color: white;
      border-bottom: ${(props) => (props.active ? '1px solid white' : 'none')};
    }
  }
`;

const NavMenuList = styled.ul`
  padding:0;
  list-style-type: none;
  margin: 0 auto 0 auto;
  text-align: center;
`;

const NavMenuListItem = styled.li`
  h1 {
    margin: 0 0px;
    font-size: 12vmin;
  }
  @media ${theme.breakpoints.size.xxl} {
    margin-bottom: 35px;
  }
  @media ${theme.breakpoints.size.xl} {
    margin-bottom: 30px;
  }
  @media ${theme.breakpoints.size.s} {
    margin-bottom: 35px;
  }
  @media ${theme.breakpoints.size.xs} {
    margin-bottom: 25px;
  }

  @media (max-height: 600px) {
    /* h1 {
      font-size: 10vmin;
    } */

    margin-bottom: 5vmin;
  }

`;

const NavMenuListLink = styled(Link)`
  color: #FF0606;
  text-decoration: none;
  opacity: 0.5;
  :hover {
    h1 {
      color: white;
    }
  }
`;

const NavMenuListContainer = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
`;

const NavMenu = styled.div`
  align-items: center;
  background-color: #FCBBBB;
  flex-direction: column;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 100;
  display: flex;
  overflow-y: scroll;
  box-sizing: border-box;
  overflow-y: hidden;
  padding-right: var(--scroll-bar-width);
`;

const CloseButton = styled.div`
  width: 19px;
  text-align: right;
  margin-right: 3px;
  margin-bottom: 3px;
  cursor: pointer;
`;

const LogoContainer = styled.div`
  display: flex;
  color: ${theme.colors.red};
  width: 100px;

  a {
    color: inherit;
  }

  @media ${theme.breakpoints.size.l} {
    width: 80px;
  }

  @media ${theme.breakpoints.size.s} {
    width: 57px;
  }
`;

const LogoContainerInner = styled.div`
  position: relative;
  height: 0;
  padding-top: 60%;
  width: 100%;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export default Menu;
