import React from 'react';
import styled from 'styled-components';

import { theme } from '../constants/theme';

const PageTitle = ({
  mainTitleText,
}) => (
  <>
    <PageTitleContainer>
      <H1>{mainTitleText}</H1>
    </PageTitleContainer>
  </>

);

const PageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const H1 = styled.h1`
  text-align: center;
  font-weight: 400;
  line-height:0.75;
  text-transform: uppercase;
  font-size: 150px;
  margin-top: 43px;
  margin-bottom: 0;

  @media ${theme.breakpoints.size.xxl} {
    font-size: 150px;
  }
  @media ${theme.breakpoints.size.xl} {
    font-size: 100px;
    margin-top: 42px;
  }
  @media ${theme.breakpoints.size.l} {
    margin-top: 55px;
  }
  @media ${theme.breakpoints.size.m} {
    font-size: 80px;
  }
  @media ${theme.breakpoints.size.s} {
    margin-top: 50px;
    font-size: 45px;
  }
`;
export default PageTitle;
