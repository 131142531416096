import React, { useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { useIsomorphicLayoutEffect } from 'react-use';

const AnimatedLogo = React.memo(({ isAnimated = true }) => {
  const [swap, swapSet] = useState(false);
  const { x } = useSpring({
    from: { x: 0 },
    to: swap ? [{ x: isAnimated ? 405 : 0 }, { x: 0 }] : { x: 0 },
  });

  const rootRef = useRef(null);

  // Re-layout the logo on every render of AnimatedLogo component
  // to fix bug with Safari blend modes
  useIsomorphicLayoutEffect(() => {
    // rootRef.current.style.display = 'none';
    setTimeout(() => {
      // rootRef.current.style.display = '';
    }, 0);
  });

  return (
    <RootNode
      ref={rootRef}
      viewBox="0 0 800 480"
      preserveAspectRatio="xMidYMid meet"
      width="100%"
      height="100%"
      onMouseOver={() => swapSet(true)}
      onMouseOut={() => swapSet(false)}
    >
      <mask id="logo">
        <animated.g
          transform={x.interpolate((px) => `translate(${0 - px}, 0)`)}
        >
          <path
            d="M 440,0 410,480 H 670 L 800,0 Z"
            fill="white"
            strokeWidth="0"
            style={{ mixBlendMode: 'difference' }}
            shapeRendering="geometricPrecision"
          />
        </animated.g>
        <animated.g transform={x.interpolate((px) => `translate(${px}, 0)`)}>
          <path
            d="M 0,0 150,480 H 400 L 360,0 Z"
            fill="white"
            strokeWidth="0"
            style={{ mixBlendMode: 'difference' }}
            shapeRendering="geometricPrecision"
          />
        </animated.g>
      </mask>
      <rect
        mask="url(#logo)"
        id="rect"
        width="1000"
        height="2000"
        fill="currentColor"
      />
    </RootNode>
  );
});

export default AnimatedLogo;

const RootNode = styled.svg`
  transform: translateZ(0px);
`;
