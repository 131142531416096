export default function getShortLang(lang) {
  let shortLang = '/';
  if (lang === 'es-mx') {
    shortLang = '/es';
  }

  if (lang === 'pr-br') {
    shortLang = '/pr';
  }

  return shortLang;
}
