import { useIsomorphicLayoutEffect } from 'react-use';
import { disableBodyScroll, enableBodyScroll, locks } from '../lib/bodyScrollLock';

export default function useBodyScrollLock({
  ref, locked = false,
} = {}) {
  useIsomorphicLayoutEffect(() => {
    if (ref.current && locked) {
      const el = ref.current;

      const scrollBarGap = window.innerWidth - document.documentElement.clientWidth;

      document.body.style.setProperty('--scroll-bar-width', `${scrollBarGap}px`);

      Array.from(document.querySelectorAll('.fixed-content')).forEach((v) => {
        // eslint-disable-next-line no-param-reassign
        v.style.paddingRight = `${scrollBarGap}px`;
      });

      disableBodyScroll(el, {
        reserveScrollBarGap: true,
      });

      // We add overflow hidden to the `html` tag
      // as well as body-scroll-lock's <body> change due to a gatsby CSS file
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';

      return () => {
        // If this is the last lock inside of body-scroll-lock
        // prepare to remove the additional html style
        if (locks.length === 1) {
          document.getElementsByTagName('html')[0].style = '';
        }

        const removeFixedContentPadding = () => {
          document.body.style.removeProperty('--scroll-bar-width');
          Array.from(document.querySelectorAll('.fixed-content')).forEach((v) => {
            // eslint-disable-next-line no-param-reassign
            v.style.paddingRight = '';
          });
        };

        enableBodyScroll(el);
        removeFixedContentPadding();
      };
    }

    return () => {};
  }, [ref, locked]);
}
