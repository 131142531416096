import styled from 'styled-components';
import React from 'react';
import { Link } from 'gatsby';

import { Hamburger } from '../images/hamburger';
import { WhiteHamburger } from '../images/whiteHamburger';
import { theme } from '../constants/theme';
import AnimatedLogo from './AnimatedLogo';
import getShortLang from '../utils/getShortLang';

const NavBar = ({
  color, lang, nav, isHome,
}) => {
  const shortLang = getShortLang(lang);

  const logo = (
    <LogoContainer className={`color-${color}`}>
      <LogoContainerInner>
        <AnimatedLogo isAnimated={!isHome} />
      </LogoContainerInner>
    </LogoContainer>
  );

  return (
    <Header className={isHome ? 'fixed-content' : ''}>
      {isHome ? logo
        : (
          <Link to={shortLang}>
            {logo}
          </Link>
        )}

      <HamburgerContainer onClick={() => nav.setNavOpen(true)}>
        {color === 'white' ? <WhiteHamburger /> : <Hamburger />}
      </HamburgerContainer>
    </Header>
  );
};

const HamburgerContainer = styled.div`
  flex: 1;
  text-align: end;
  cursor: pointer;
`;

const LogoContainer = styled.div`
  color: ${theme.colors.red};
  width: 100px;

  @media ${theme.breakpoints.size.l} {
    width: 80px;
  }

  @media ${theme.breakpoints.size.s} {
    width: 57px;
  }

  &.color-white {
    color: white;
  }
`;

const LogoContainerInner = styled.div`
  position: relative;
  height: 0;
  padding-top: 60%;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Header = styled.header`
  display: flex;
  margin: 22px 81px 22px 81px;
  z-index: 2;
  position: relative;

  @media ${theme.breakpoints.size.l} {
   margin: 22px 40px 22px 40px;
  }

  @media ${theme.breakpoints.size.m} {
    margin: 22px 40px 22px 40px;
  }

  @media ${theme.breakpoints.size.s} {
   margin: 20px;
  }
`;

export default NavBar;
